import React, { Fragment } from 'react';
import {Helmet} from 'react-helmet'
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faLock,
  faSkullCrossbones,
  faAddressCard,
  faAd,
  faDollarSign,
  faShoePrints,
  faRoute,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import TopNavbar from '../components/TopNavbar.js';

// markup
const IndexPage = () => {
  return (
    <Fragment>
      <Helmet>
        {/* General tags */}
        <title>Unibits</title>
        <meta name="description" content="Secure your digital life" />
      </Helmet>
      <TopNavbar />
      <main>
        <section className="section pb-6 bg-gradient-primary">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-lg-2 ml-lg-auto" md="6">
                <div className="position-relative pl-md-5">
                  <img
                    alt="..."
                    className="img-center img-fluid"
                    src={require('../images/logo.svg')}
                  />
                </div>
              </Col>
              <Col className="order-lg-1" lg="6">
                <div className="d-flex px-3">
                  <div className="pl-4">
                    <h4 className="display-3 text-white">Secure your digital life</h4>
                    <p className="text-white">Private access to the open internet.</p>
                  </div>
                </div>
                <Card className="shadow shadow-lg--hover mt-5">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                          <FontAwesomeIcon icon={faLock} />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-success">Access content safely</h5>
                        <p>
                          Enjoy everything good that the internet has to offer, worry-free. Get
                          instant, secured access to all the content you love.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="shadow shadow-lg--hover mt-5">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <FontAwesomeIcon icon={faShoePrints} />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-warning">Leave no footprints</h5>
                        <p>
                          Use Unibits VPN to make sure your location is private and your sensitive
                          data is secure, at all times.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <Row className="justify-content-center text-center mb-lg">
              <Col lg="8">
                <h2 className="display-3">Without a VPN you are exposed to real online threats</h2>
              </Col>
            </Row>
            <Row>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 pb-4 text-center">
                  <FontAwesomeIcon icon={faChalkboardTeacher} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">Hacking</span>
                    <small className="h6 text-muted">
                      Data thieves look for unprotected devices, and those that do not use
                      encryption are easy targets.
                    </small>
                  </h5>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 pb-4 text-center">
                  <FontAwesomeIcon icon={faSkullCrossbones} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">Malware & phishing</span>
                    <small className="h6 text-muted">
                      These types of attacks are common, and it’s difficult to avoid them without
                      protection.
                    </small>
                  </h5>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 text-center">
                  <FontAwesomeIcon icon={faAddressCard} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">Identity theft</span>
                    <small className="h6 text-muted">
                      If you don’t use security software, you might end up getting your login
                      credentials stolen.
                    </small>
                  </h5>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 pb-4 text-center">
                  <FontAwesomeIcon icon={faDollarSign} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">Price discrimination</span>
                    <small className="h6 text-muted">
                      Online businesses might show you higher prices if they see you are in a more
                      affluent city.
                    </small>
                  </h5>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 pb-4 text-center">
                  <FontAwesomeIcon icon={faRoute} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">ISP tracking</span>
                    <small className="h6 text-muted">
                      Without a VPN, your internet provider can collect & sell your data to
                      advertisers.
                    </small>
                  </h5>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="pt-4 pb-4 text-center">
                  <FontAwesomeIcon icon={faAd} size="6x" />
                  <h5 className="title">
                    <span className="d-block my-1">Ad manipulation</span>
                    <small className="h6 text-muted">
                      It is easy for advertisers to influence your behavior if you expose all your
                      browsing habits.
                    </small>
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <footer className="footer has-cards">
          <Container>
            <hr />
            <Row>
              <Col>© 2021 Unibits. All rights reserved.</Col>
              <Col className="text-right">
                <a href="mailto:admin@unibits.io">
                  <FontAwesomeIcon icon={faEnvelope} /> Contact Us
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </main>
    </Fragment>
  );
};

export default IndexPage;
