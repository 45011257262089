import React from 'react';
import { Link } from 'gatsby';

// reactstrap components
import { Button, NavbarBrand, Navbar, NavItem, Nav, Container } from 'reactstrap';

class TopNavbar extends React.Component {
  render() {
    return (
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img alt="..." className="img-center img-fluid" src={require('../images/logo.svg')} />
            </NavbarBrand>
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem className="d-none d-lg-block ml-lg-4">
                <Button
                  className="btn-neutral"
                  color="default"
                  href="https://app.unibits.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="nav-link-inner--text ml-1">Log in</span>
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default TopNavbar;
